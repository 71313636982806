import { BrowserRouter, Route, Switch, Link, NavLink} from 'react-router-dom';
import {Helmet} from "react-helmet"; // Used to change HTML head tags in React

// page imports
import Home from './pages/home/Home'
import About from './pages/about/About'
import Projects from './pages/projects/Projects'
import Navbar from './components/Navbar';
import Footer from './components/Footer'

import styles from './index.css'

// Add background img attribution before publishing,
// instructions are on laptop desktop
function App() {
  return (
    <div className="App">

      <Helmet>
        <meta charSet="utf-8" />
        <title>Thomas Morris</title>
        <link rel="canonical" href="src\images\thumbnail.jpg" />
      </Helmet>

      <BrowserRouter>

        <Navbar/>

        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          <Route path="/about">
            <About />
          </Route>
        </Switch>

        <Footer />
        
      </BrowserRouter>
    </div>
  );
}

export default App;
