//styles
import styles from './Home.css'

export default function Home() {

    return (

        <div className='top'>
            <div className='cover'>
                <div className='cover-bio'>
                    <h1>Thomas Morris</h1>
                    <h3>software engineer</h3>
                </div>
            </div>
            <div className='welcome'>
                <h1>Welcome to my portfolio</h1>
                <p className='me'>After joining the military and succeeding as a professional soldier, I began to explore what the next step in my career could be. I began pursuing software 
                engineering because of my undying curiosity and to chase a career that could allow to me grow for as long as I wanted. In addition to working with software, programming 
                allowed me to express the creative side of myself and work in many different fields. 
                </p>
            </div>
            <form action="https://formsubmit.co/afaa7f925d19e9e780ff023473e81aa1" method="POST" className={styles.contact}>
                <h2>Contact Me</h2>
                <p>If you are interested in collaborating or want to find out more about my work, message me here and I'll get back to you as soon as possible.</p>
                <input type="text" name="name" placeholder='Name' required/>
                <input type="email" name="email" placeholder='Email' required/>
                <textarea type="text" class="scrollabletextbox" name="note" placeholder='Message' required/>
                <input type="hidden" name="_captcha" value="true"></input>
                <button type="submit">Send</button>
            </form>
        </div>
    )
}