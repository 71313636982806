import styles from './Projects.css'

import { BiLike } from "react-icons/bi";

import { projectFirestore } from '../../firebase/config';
import { useEffect, useState } from 'react';


export default function Projects() {
    const [data, setData] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        setIsPending(true)

        projectFirestore.collection('stories').get().then((snapshot) => {
            if (snapshot.empty) {
                setError('No stats to load')
                setIsPending(false)
            } else {
                let results = []
                snapshot.docs.forEach(doc => {
                    results.push({id: doc.id, ...doc.data()})
                })
                setData(results)
                setIsPending(false)
            }
        }).catch(err => {
            setError(err.message)
            setIsPending(false)
        })

    }, [])

    return (
        <div className='projects'>
            <h1 className='projtitle'>Projects</h1>
            <div className='cards'>
                <a href='https://github.com/thomasokc/jobBot' target="_blank">
                    <div className='card'>
                        <img src={require('../../images/corporate.jpg')} className='cardImg' />
                        <div className='cardCont'>
                            <p>This is a bot I'm creating that will automatically apply to jobs you want on LinkedIn. It is runs with Python and
                                Selenium, and will need to be updated as LinkedIn updates their website.
                            </p>
                        </div>
                        <div className='cardInfo'>
                            <div>
                                <BiLike /> 1K
                            </div>
                        </div>
                    </div>
                </a>
                <a href='https://weatherapp-d5b39.web.app/' target="_blank">
                    <div className='card'>
                        <img src={require('../../images/Forecasted.png')} className='cardImg' />
                        <div className='cardCont'>
                            <p>This is a weather app that I made with OpenStreetMap, Leaflet, and OpenWeatherMap to create an engaging report on weather data in user requested areas.</p>
                        </div>
                        <div className='cardInfo'>
                            <div>
                                <BiLike /> 1K
                            </div>
                        </div>
                    </div>
                </a>
                <a href='https://momoney-2a555.web.app/' target="_blank">
                    <div className='card'>
                        <img src={require('../../images/moMoney.png')} className='cardImg' />
                        <div className='cardCont'>
                            <p>A finance tracking app I created with React, Firestore Database, and Google Authentication that updates input in real time.</p>
                        </div>
                        <div className='cardInfo'>
                            <div>
                                <BiLike /> 1K
                            </div>
                        </div>
                    </div>
                </a>
                <a href='https://solscan.io/token/64r432xziX6XHx8QMuHa4Ck7ayzVg2fe7cpPvFD3MPYX' target="_blank">
                    <div className='card'>
                        <img src={require('../../images/crypto.jpg')} className='cardImg' />
                        <div className='cardCont'>
                            <p>I created my own crypto token, Morris Coin, on the Solana blockchain for fun! 10,000 tokens are available, and each one is worth 0.05% of a penny!</p>
                        </div>
                        <div className='cardInfo'>
                            <div>
                                <BiLike /> 1K
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div >
    )
}