//styles
import styles from './Footer.module.css'

export default function Footer() {

    return (
        <footer>
            <div className={styles.footerContent}>
                <h3>Thomas Morris</h3>
                <ul className={styles.socials}>
                    <li><a href='https://github.com/thomasokc' target="_blank"><img src={require('../images/github.png')} className={styles.gh} /></a></li>
                    <li><a href='https://www.linkedin.com/in/thomasokc/' target="_blank"><img src={require('../images/linkedin.png')}
                        className={styles.li} /></a></li>
                </ul>
            </div>
            <div className={styles.footerBottom}>
                <p>copyright &copy;2022 Thomas Morris 2022</p>
            </div>
        </footer>
    )
}