import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyDIFYe7LeF3auzWAN4hD9KI3k9sfOmjrdc",
    authDomain: "portfolio-3343f.firebaseapp.com",
    projectId: "portfolio-3343f",
    storageBucket: "portfolio-3343f.appspot.com",
    messagingSenderId: "599846346002",
    appId: "1:599846346002:web:2fd057dbceec9c60c967cb"
};

// init firebase
firebase.initializeApp(firebaseConfig)

// init services
const projectFirestore = firebase.firestore()

export {projectFirestore}