import Helmet from 'react-helmet'
import styles from './About.css'

export default function About() {

    return (
        <>

            <Helmet>
                <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"/>
            </Helmet>

            <div className='about'>

                <a href="src\assets\resume2.pdf" download className="download-btn">Download Resume
                    <i className="fa fa-download"></i>
                </a>

                <div className='about1 aboutcard'>
                    <img src={require('../../images/code.jpg')} className='code' />
                    <div className='about1cont'>
                        <h3>Programming</h3>
                        <p>I began programming during the beginning of the COVID-19 pandemic as a way to keep my mind going during quarantine. I have always
                            been a visual learner, so I began teaching myself front-end web development. Creating different applications was incredibly fun and rewarding,
                            and when I saw the potential for a satisfying career, I couldn't turn back. Now I'm a Fullstack Software Engineer that has
                            worked with to many technologies to count.
                        </p>
                    </div>
                </div>

                <div className='about2 aboutcard'>
                    <img src={require('../../images/parachute.jpg')} className='code' />
                    <div className='about2cont'>
                        <h3>Military</h3>
                        <p>I served in the Army as an Arctic Paratrooper with the <a href="https://home.army.mil/alaska/index.php/USARAK-units/4-25th-ibct" target="_blank">4th Brigade Combat Team (ABN), 25th Infantry Division</a> at Joint Base Elmendorf Richardson
                            in Alaska. During that time I learned how to work closely with small teams in large scale operations, operating in various
                            technical and leadership positions.
                        </p>
                    </div>
                </div>

                <div className='about3 aboutcard'>
                    <img src={require('../../images/kayak.jpg')} className='code' />
                    <div className='about1cont'>
                        <h3>Outdoors</h3>
                        <p>I spent a large amount of my childhood visiting my family in the Rocky Mountains, where we spent most of our time downhill
                            skiing and camping in the backcountry. After my military service I ended up working as a ski instructor at <a href="https://www.alyeskaresort.com/" target="_blank">Alyeska Resort</a> where I
                            was voted 'Rookie of the Year' and Most 'Imporved Insrtuctor', this was followed by a season of backcountry and wildlife
                            guiding in the <a href="https://www.britannica.com/place/Chugach-Mountains" target="_blank">Chugach Mountains</a>.
                        </p>
                    </div>
                </div>

            </div>
        </>
    )
}