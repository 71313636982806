import {Link} from 'react-router-dom'

//styles
import styles from './Navbar.module.css'

export default function Navbar() {

    return (
        <nav className={styles.navbar}>
            <nav>
                <Link to='/'>Home</Link>
                <Link to='/about'>About</Link>
                <Link to='/projects'>Projects</Link>
            </nav>
        </nav>
    )
}